import Choices from "choices.js";
import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";

const COMPASIONS_TABLE_ID = "smart_task_condition_table"
const ACTIONS_TABLE_ID = "smart_task_action_table"

export default class extends ApplicationController {
  static targets = [
    "sidebar_table",
    "choices_taskable",
    "choices_child",
    "choices_trigger",
    "schedule_form_div",
    "schedule_input",
    "choices_conditionable",
    "choices_comparison_attribute",
    "choices_comparison_select_value",
    "choices_comparison_operator",
    "comparison_value_type",
    "compasion_add",
    "sidebar_table",
    "choices_record_model",
    "choices_record_attribute",
    "choices_new_value"
  ]

  connect() {
    // choices_taskable
    if (this.hasChoices_taskableTarget) this.initChoicesTaskable();
    // choices_child
    if (this.hasChoices_childTarget) this.initChoicesChild();
    // choices_trigger
    if (this.hasChoices_triggerTarget) this.initChoicesTrigger();
    // choices_conditionable
    if (this.hasChoices_conditionableTarget) this.initChoicesConditionable();
    // choices_comparison_attribute
    if (this.hasChoices_comparison_attributeTarget) this.initChoicesAttibute();
    // choices_comparison_operator
    if (this.hasChoices_comparison_operatorTarget) this.initChoices(this.choices_comparison_operatorTarget);
    // choices_comparison_select_value
    if (this.hasChoices_comparison_select_valueTarget) this.initChoices(this.choices_comparison_select_valueTarget);
    // choices_record_model
    if (this.hasChoices_record_modelTarget) this.initChoicesRecords(this.choices_record_modelTarget);
    // choices_record_attribute
    if (this.hasChoices_record_attributeTarget) this.initChoicesRecordAttributes(this.choices_record_attributeTarget);
    // choices_new_value
    if (this.hasChoices_new_valueTarget) this.initChoices(this.choices_new_valueTarget);
    // sidebar table
    if (this.hasSidebar_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createSidebarTable(content_url);
    }

  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  changeScheduleType(event) {
    let current_radio = event.currentTarget;
    let parent_row = current_radio.closest(".row")
    // disable all
    this.disableAllScheduleFields()
    // enable

    const schedule_inputs = parent_row.querySelectorAll(".schedule_input");
    schedule_inputs.forEach(el => {
      if (el.choices) el.choices.enable();
      else el.removeAttribute("disabled")
    })
  }

  // add new condition
  async addNewCondition(event) {
    event.preventDefault();

    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    await this.addConditionRequest(url)
  }

  async addNewChangeAction(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    await this.addActionRequest(url)
  }

  // removeCondition
  removeCondition(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
      tr.remove();
      this.reindexFormTableRows(COMPASIONS_TABLE_ID);
    }
  }

  removeAction(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
      tr.remove();
      this.reindexFormTableRows(ACTIONS_TABLE_ID);
    }
  }

  // private

  // create sidebar
  async createSidebarTable(url) {
    const columns = this.data.get("table-columns");
    // const per_page = this.data.get("per-page");
    const table_target = this.sidebar_tableTarget;
    // const sort_column = this.data.get("sort-column");
    // const sort_dir = this.data.get("sort-dir");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "smart_task_users_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // sort
      // initialSort: [
      //   { column: sort_column, dir: sort_dir }
      // ],
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        // if (data.status) row.getElement().classList.add("row-" + data.status)
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          // row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);

    // this.setSidebarCurrentFilter();
  }

  // Show smart task
  async createContent(content_url) {
    const content_target = document.getElementById("smart_task_content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    // super.updateSidebarStick();
  }


  //
  // reindex form table
  //
  reindexFormTableRows(table_id) {
    const table = document.getElementById(table_id)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1

      const condition_weight = tr.querySelector("input.condition_weight")
      condition_weight.value = i + 1
    }
  }


  // add new service to form
  async addConditionRequest(url) {

    if ( this.validateTargetField(this.choices_conditionableTarget) || 
         this.validateTargetField(this.choices_comparison_attributeTarget) ||
         this.validateTargetField(this.choices_comparison_operatorTarget) ||
         this.validateTargetField(this.choices_comparison_select_valueTarget) ) {
      return false;
    }

    const conditionable_choices = this.choices_conditionableTarget.choices;
    const comparison_attribute_choices = this.choices_comparison_attributeTarget.choices;
    const comparison_operator_choices = this.choices_comparison_operatorTarget.choices;
    const comparison_value_choices = this.choices_comparison_select_valueTarget.choices;

    const data = new FormData();
    data.append("conditionable_type", conditionable_choices.getValue(true));
    data.append("comparison_attribute", comparison_attribute_choices.getValue(true));
    data.append("comparison_operator", comparison_operator_choices.getValue(true));
    data.append("comparison_value", comparison_value_choices.getValue(true));

    await fetch(url, {
      body: data,
      method: "POST",
      dataType: "text/html",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then(function (response) {
      if (response.status == 422) {
        response.text().then(function (text) {
          console.log(text)
        });
      } else {
        response.text().then(function (text) {
          const table = document.getElementById(COMPASIONS_TABLE_ID)
          const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
          let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
          table.tBodies[0].insertAdjacentHTML("beforeend", html);
        });
      }
    })

    this.clearValueAndOperatorFields();
    this.clearAttributesChoice();
    this.choices_conditionableTarget.choices.setChoiceByValue("");
  }

  async addActionRequest(url) {

    if ( this.validateTargetField(this.choices_record_modelTarget ) || 
          this.validateTargetField(this.choices_record_attributeTarget  ) ||
          this.validateTargetField(this.choices_new_valueTarget ) ) {
      return false;
    }

    const record_model_choices = this.choices_record_modelTarget.choices;
    const record_attribute_choices = this.choices_record_attributeTarget.choices;
    const new_value_choices = this.choices_new_valueTarget.choices;

    const data = new FormData();
    data.append("record_model", record_model_choices.getValue(true));
    data.append("record_attribute", record_attribute_choices.getValue(true));
    data.append("new_value", new_value_choices.getValue(true));

    await fetch(url, {
      body: data,
      method: "POST",
      dataType: "text/html",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then(function (response) {
      if (response.status == 422) {
        response.text().then(function (text) {
          console.log(text)
        });
      } else {
        response.text().then(function (text) {
          const table = document.getElementById(ACTIONS_TABLE_ID)
          const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
          let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
          table.tBodies[0].insertAdjacentHTML("beforeend", html);
        });
      }
    }
    )

    this.clear_target_choices(this.choices_record_attributeTarget);
    this.clear_target_choices(this.choices_new_valueTarget);
    this.choices_record_modelTarget.choices.setChoiceByValue("");
  }


  validateTargetField(target) {
    let value = target.choices.getValue(true);
    if (value == undefined) {
      target.closest(".choices").classList.add("error");
      return true;
    } else {
      target.closest(".choices").classList.remove("error");
      return false;
    }
  }

  disableAllScheduleFields() {
    let schedule_form_div = document.getElementById("smart_task_schedule")
    if (schedule_form_div) {
      const schedule_inputs = schedule_form_div.querySelectorAll(".schedule_input");

      schedule_inputs.forEach(el => {
        if (el.choices) el.choices.disable();
        else el.setAttribute("disabled", "disabled")
      })
    }
  }


  // initChoicesOperator  initChoicesValue

  initChoices(target) {
    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    target.choices = choice

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
      },
      false,
    );

    this.setItemsWidthToMaxWidth(target)
  }

  // initChoicesAttibute
  initChoicesAttibute() {
    let choice = new Choices(this.choices_comparison_attributeTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.choices_comparison_attributeTarget.choices = choice

    let form = this.choices_comparison_attributeTarget.closest('form')
    let controller = this.thisController(form)

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        controller.clearValueAndOperatorFields();
        controller.reloadValueAndOperatorField(event)
      },
      false,
    );
    this.setItemsWidthToMaxWidth(this.choices_comparison_attributeTarget)
  }

  async reloadValueAndOperatorField(event) {
    let conditionable_target = this.choices_conditionableTarget
    let conditionable_value = conditionable_target.choices.getValue(true)
    let value_target_choices = this.choices_comparison_select_valueTarget
    let operator_target_choices = this.choices_comparison_operatorTarget

    const url = this.choices_comparison_attributeTarget.getAttribute("data-url")
    let url_with_param = url + "&conditionable=" + conditionable_value + "&attribute=" + event.detail.value;

    let value_choices = value_target_choices.choices
    let operator_choices = operator_target_choices.choices

    value_choices.clearInput();
    value_choices.clearStore();
    value_choices.clearChoices();

    operator_choices.clearInput();
    operator_choices.clearStore();
    operator_choices.clearChoices();

    let data = await fetch(url_with_param)
      .then((response) => response.json())

    value_target_choices.closest("div").classList.remove("hidden")

    value_choices.setChoices(data.items);
    value_choices.enable()

    operator_choices.setChoices(data.comparison_operators);
    operator_choices.enable();
  }

  // initChoicesConditionable
  initChoicesConditionable() {
    let choice = new Choices(this.choices_conditionableTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.choices_conditionableTarget.choices = choice

    let form = this.choices_conditionableTarget.closest('form')
    let controller = this.thisController(form)

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        controller.clearValueAndOperatorFields();
        controller.reloadAttributesChoices(event);
      },
      false,
    );

    this.addValidateTrigger(this.choices_triggerTarget, this.choices_triggerTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_conditionableTarget)
  }

  // initChoicesRecords
  initChoicesRecords(target) {
    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    target.choices = choice

    let form = target.closest('form')
    let controller = this.thisController(form)

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        controller.clear_target_choices(controller.choices_record_attributeTarget);
        controller.clear_target_choices(controller.choices_new_valueTarget);
        controller.reloadActionsAttributesChoices(event);
      },
      false,
    );

    this.addValidateTrigger(target, target.choices)
    this.setItemsWidthToMaxWidth(target)
  }

  // initChoicesRecordAttributes
  
  initChoicesRecordAttributes(target) {
    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    target.choices = choice

    let form = target.closest('form')
    let controller = this.thisController(form)

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        controller.clear_target_choices(controller.choices_new_valueTarget);
        controller.reloadActionsNewValuesChoices(event);
      },
      false,
    );

    this.addValidateTrigger(target, target.choices)
    this.setItemsWidthToMaxWidth(target)
  }


  reloadAttributesChoices(event) {
    let attribute_target = this.choices_comparison_attributeTarget
    const url = this.choices_conditionableTarget.getAttribute("data-url")

    let url_with_param = url + "&conditionable=" + event.detail.value;
    let attribute_choices = attribute_target.choices
    attribute_choices.clearInput();
    attribute_choices.clearStore();
    attribute_choices.clearChoices();
    // attribute_choices.setChoiceByValue(null);

    attribute_choices.setChoices(async () => {
      try {
        const items = await fetch(url_with_param);
        return items.json();
      } catch (err) {
        console.error(err);
      }
    });
  }


  reloadActionsAttributesChoices(event) {
    let target = this.choices_record_modelTarget;
    let linked_target = this.choices_record_attributeTarget;
    const url = target.getAttribute("data-url")

    let url_with_param = url + "&actionable=" + event.detail.value;
    let attribute_choices = linked_target.choices
    attribute_choices.clearInput();
    attribute_choices.clearStore();
    attribute_choices.clearChoices();

    attribute_choices.setChoices(async () => {
      try {
        const items = await fetch(url_with_param);
        return items.json();
      } catch (err) {
        console.error(err);
      }
    });
  }

  reloadActionsNewValuesChoices(event) {
    let target = this.choices_record_attributeTarget;
    let linked_target = this.choices_new_valueTarget;
    let record_target = this.choices_record_modelTarget;
    let actionable_value = record_target.choices.getValue(true);
    const url = target.getAttribute("data-url")

    let url_with_param = url + "&actionable=" + actionable_value + "&attribute=" + event.detail.value;
    let attribute_choices = linked_target.choices
    attribute_choices.clearInput();
    attribute_choices.clearStore();
    attribute_choices.clearChoices();

    attribute_choices.setChoices(async () => {
      try {
        const items = await fetch(url_with_param);
        return items.json();
      } catch (err) {
        console.error(err);
      }
    });
  }


  clearAttributesChoice(){
    let attribute_target = this.choices_comparison_attributeTarget
    let attribute_choices = attribute_target.choices

    attribute_choices.clearInput();
    attribute_choices.clearStore();
    attribute_choices.clearChoices();
    attribute_choices.disable()
  }

  clearValueAndOperatorFields(){
    let value_target = this.choices_comparison_select_valueTarget
    let operator_target = this.choices_comparison_operatorTarget

    this.clear_target_choices(value_target)
    this.clear_target_choices(operator_target)
  }

  clear_target_choices(target){
    let choices = target.choices
    choices.clearInput();
    choices.clearStore();
    choices.clearChoices();
    choices.disable();
  }


  initChoicesTrigger() {
    let choice = new Choices(this.choices_triggerTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.choices_triggerTarget.choices = choice


    if (this.hasSchedule_form_divTarget) {
      let schedule_form_div = this.schedule_form_divTarget
      let disable_schelude_inputs = this.disableAllScheduleFields
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          disable_schelude_inputs();
          let schedule_form_div = document.getElementById("smart_task_schedule")
          let radio = schedule_form_div.querySelector('input.schedule_type[type=radio]:checked');
          if (radio) radio.checked = false;

          if (event.detail.value == "by_time") {
            schedule_form_div.classList.remove("disabled_div");
          } else {
            schedule_form_div.classList.add("disabled_div");
          }
        },
        false,
      );
    }
    this.addValidateTrigger(this.choices_triggerTarget, this.choices_triggerTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_triggerTarget)
  }

  initChoicesTaskable() {
    let choice = new Choices(this.choices_taskableTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.choices_taskableTarget.choices = choice

    let childs = this.choices_taskableTarget.getAttribute("data-childs-tree")
    if (childs) {
      childs = JSON.parse(childs)
      let child_target = this.choices_childTarget

      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          child_target.choices.clearInput();
          child_target.choices.clearStore();
          child_target.choices.clearChoices();
          // child_target.choices.setChoiceByValue(null);
          child_target.choices.setChoices(childs[event.detail.value], "value", "label", false)
        },
        false,
      );
    }

    this.addValidateTrigger(this.choices_taskableTarget, this.choices_taskableTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_taskableTarget)
  }

  initChoicesChild() {
    this.choices_childTarget.choices = new Choices(this.choices_childTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.addValidateTrigger(this.choices_childTarget, this.choices_childTarget.choices)
    this.setItemsWidthToMaxWidth(this.choices_childTarget)
  }

  // // // // // // // Choices Helper // // // // // //

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');
    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

  thisController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "smart-tasks--main")
  }

  setItemsWidthToMaxWidth(html_element) {
    const choose_div = html_element.closest(".choices")
    let choices_object = html_element.choices

    choices_object.passedElement.element.addEventListener(
      'showDropdown',
      function (event) {
        const choices_dropdown = choose_div.querySelector(".choices__list--dropdown")
        const items = choices_dropdown.getElementsByClassName('choices__item--selectable')
        if (items.length > 0) {
          const widths = [].slice.call(items).map(function (div) { return div.getBoundingClientRect().width; });
          const maxWidth = Math.max.apply(null, widths);
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            item.style.width = maxWidth + "px";
          }
        }
      },
      false,
    );
  }

  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора",

      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }


}